import { apiUrls, defaultClient, authlessClient } from './accessToken'
import initAccessToken from './accessToken'

export { defaultClient as apiClient, authlessClient } from './accessToken'
export const defaultPageSize = 100

// initialize access token and user authentication handling
initAccessToken()

function parseParams(params: Record<string, any>) {
  // TODO: encodeUriComponent?
  const paramList = Object.keys(params).map((key) => {
    const value = params[key]
    return `${key}=${value}`
  })
  return `?${paramList.join('&')}`
}

function apiUrl(url: string, version: number, params?: Record<string, any>) {
  const paramString = params ? parseParams(params) : ''
  if (url.startsWith('http://') || url.startsWith('https://')) return `${url}${paramString}`
  // return url preceded by api url, after replacing multiple slashes (like //) except for ://
  return `${apiUrls[version]}${url}${paramString}`.replace(/(?<!:)\/+/gm, '/')
}

export async function apiFetch<T>(url: string, params?: Record<string, any>, version = 1, noAuth = false): Promise<T> {
  const client = noAuth ? authlessClient : defaultClient
  const response = await client.get<T>(apiUrl(url, version, params))
  return response.data
}

export async function apiUpdate<T>(url: string, payload: any, version = 1, noAuth = false): Promise<T> {
  const client = noAuth ? authlessClient : defaultClient
  const response = await client.patch<T>(apiUrl(url, version), payload)
  return response.data
}

export async function apiCreate<T>(url: string, payload: any, version = 1, noAuth = false, usePut = false): Promise<T> {
  const client = noAuth ? authlessClient : defaultClient
  const method = usePut ? client.put : client.post
  const response = await method<T>(apiUrl(url, version), payload)
  return response.data
}

export async function apiDelete<T>(url: string, version = 1, noAuth = false, payload?: any) {
  const client = noAuth ? authlessClient : defaultClient
  const response = await client.delete<T>(apiUrl(url, version), payload)
  return response
}

export async function apiOptions<T>(url: string, version = 1, noAuth = false) {
  const client = noAuth ? authlessClient : defaultClient
  const response = await client.options<T>(apiUrl(url, version))
  return response
}
