import { z } from 'zod'
import { authlessClient, apiFetch, apiCreate, apiUpdate, apiDelete } from './client'
import { login as loginFunc, type LoginCredentials } from './accessToken'

import { AxiosError } from 'axios'
import { validateObjectWithPattern } from '@util/api'
import { success, failure, snakeCased, paginatedPatternZod } from '@util/types'

const userSettingsPattern = z.object({
  dataset: z.string(),
  language: z.string().optional(),
  onboarding: z.object({ materiality: z.boolean() }).optional(),
})

const userPermissionPattern = z.object({
  id: z.string().uuid(),
  created_at: z.string().datetime(),
  modified_at: z.string().datetime(),
  string: z.string(),
  description: z.string(),
})

const userPattern = z.object({
  id: z.string().uuid(),
  email: z.string().email(),
  email_verified: z.boolean(),
  first_name: z.string(),
  last_name: z.string(),
  date_joined: z.string().datetime(),
  hierarchy_id: z.string(),
  is_active: z.boolean(),
  is_reporting_entity_admin: z.boolean(),
  is_staff: z.boolean(),
  is_external: z.boolean(),
  is_onboarded: z.boolean(),
  position: z.string(),
  private_settings: userSettingsPattern.nullable(),
  reporting_entity: z.string(),
  get_reporting_entity_name: z.string(),
  permissions: userPermissionPattern.array(),
})

/* eslint-disable @typescript-eslint/no-unused-vars */
const authErrorPattern = z.object({
  non_field_errors: z.string(),
  detail: z.string(),
  new_password: z.string().array().optional(),
})

export type User = z.infer<typeof userPattern>
type AuthErrorResponse = z.infer<typeof authErrorPattern>
type EmptyResult = Promise<Result<void, void>>

export async function passwordResetRequest(email: Email, resetLink: URL['href']): EmptyResult {
  const response = await authlessClient.post<void>('/auth/users/reset_password/', {
    email,
    password_reset_link: resetLink,
  })
  return response.status === 204 ? success(undefined) : failure(undefined)
}

export async function passwordReset(uid: string, token: string, newPassword: string): EmptyResult {
  const response = await authlessClient.post(
    '/auth/users/reset_password_confirm/',
    snakeCased({ uid, token, newPassword }),
  )
  return response.status === 204 ? success(undefined) : failure(undefined)
}

export async function getProfile(): Promise<Result<User>> {
  const url = import.meta.env.VITE_USER_URL

  try {
    const dto = await apiFetch(url)
    return validateObjectWithPattern<User>(dto, userPattern, url)
  } catch (err) {
    return failure(err as AxiosError)
  }
}

export async function login(credentials: LoginCredentials): Promise<Result<User>> {
  try {
    await loginFunc(credentials)
    return getProfile()
  } catch (err) {
    return failure(err as AxiosError)
  }
}

export async function signup(formData: {
  uid: string
  token: string
  newPassword: string
  firstName: string
  lastName: string
  position: string
}): Promise<User> {
  const { uid, token, newPassword, firstName, lastName, position } = formData

  try {
    const response = await authlessClient.post('/auth/users/activation/', {
      uid,
      token,
      first_name: firstName,
      last_name: lastName,
      position,
      new_password: newPassword,
    })
    const result = await login({ email: response.data.email, password: newPassword })
    if (result.success) return result.data
    else return Promise.reject(new Error('generic')) //TODO: introduce a more specific error?
  } catch (err) {
    let message = 'generic'
    if (err instanceof AxiosError) {
      const data: AuthErrorResponse = err.response?.data

      if (data.non_field_errors) message = 'alreadyActivated'
      else if (data.detail === 'Not a valid token.') message = 'invalid'
      else if (data.detail === 'Not found.') message = 'notFound'
      else if (data.new_password && data.new_password.length) {
        message = data.new_password[0]
      }
    }

    return Promise.reject(new Error(message))
  }
}

export async function getUserProfiles(page: number): Promise<Paginated<User>> {
  const url = 'auth/users/'
  const params: Record<string, any> = {
    //TODO: add entries per page parameter when supported
    page: page,
  }
  const response = await apiFetch<Paginated<User>>(url, params)
  return response
}

export async function getUserProfile(id: string): Promise<User> {
  const url = `auth/users/${id}/`
  return await apiFetch<User>(url)
}

export async function inviteUser(user: Partial<User>): Promise<Partial<User>> {
  const url = `auth/users/`
  const response = await apiCreate<Partial<User>>(url, user)
  return response
}

export async function updateUserDetails(user: Partial<User>, id: string): Promise<User> {
  const url = `auth/users/${id}/`
  return await apiUpdate<User>(url, user)
}

export async function deleteUser(id: string): Promise<void> {
  const url = `auth/users/${id}`
  await apiDelete<User>(url)
}

export async function resendUserActivation(email: string): Promise<void> {
  const url = `auth/users/resend_activation/`
  await apiCreate<{ email: string }>(url, { email: email })
}

export async function updateUser(userId: string, payload: Partial<User>): Promise<User> {
  const url = `/auth/users/${userId}/`
  return await apiUpdate<User>(url, payload)
}

export async function getUsers(filters: { reporting_entity: string; search: string }): Promise<Paginated<User>> {
  const url = 'auth/users/'
  return await apiFetch<Paginated<User>>(url, filters)
}

const ssoInfoPattern = z.object({
  id: z.string(),
  web_domains: z.string().array(),
  cognito_user_pool_id: z.string(),
  identity_provider: z.string(),
  client_id: z.string(),
  reporting_entity: z.string(),
})
type SSOInfo = z.infer<typeof ssoInfoPattern>

export async function sso(email: string): Promise<Result<SSOInfo, void>> {
  const url = 'sso-connections'
  const domain = email.split('@')[1]

  try {
    const response = await apiFetch(url, { web_domain: domain })
    const match = paginatedPatternZod(ssoInfoPattern).safeParse(response)

    if (match.success && match.data.results.length) return success(match.data.results[0])
    else return failure(undefined)
  } catch {
    return failure(undefined)
  }
}
