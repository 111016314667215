export const lettersArray = 'abcdefghijklmnopqrstuvwxyz'.split('')

// generic array fill up function, needs items param unless list is a list of strings
// returns list, but filled up to len elements, eg: fillUp(['foo'], 3) returns ['foo', 'A', 'B']
// Attention! This function does not check for duplicates! fillUp(['A'], 3) returns ['A', 'A', 'B']
export function fillUp<T>(list: T[], len: number, items?: T[]): T[]
export function fillUp(list: string[], len: number, words = lettersArray) {
  if (words.length < len - list.length) {
    throw new Error(`Cannot fill up ${len - list.length} items with only ${words.length} items available.`)
  }
  let i = 0
  while (list.length < len) list.push(words[i++])
  return list
}

export function sanitizeHTML(text: string): string {
  return text.replace(/(<([^>]+)>)/gi, '')
}

export function objectToFormData(obj: { [key: string]: any }): FormData {
  const formData = new FormData()

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      formData.append(key, value)
    }
  }

  return formData
}

export function flatToNested<T>(input: any): T {
  const output: Record<string, any> = {}

  Object.keys(input).forEach((key) => {
    // not a nested key
    if (key.indexOf('.') === -1) output[key] = input[key]
    const nested = key.split('.')
    const last = nested[nested.length - 1]

    let current = output
    nested.forEach((nestedKey) => {
      if (!current[nestedKey]) {
        if (nestedKey === last) current[nestedKey] = input[key]
        else {
          current[nestedKey] = {}
          current = current[nestedKey]
        }
      } else {
        current = current[nestedKey]
      }
    })
  })
  return output as T
}

/**
 * Polls a function with a fixed interval or a sequence of intervals (in seconds).
 *
 * @param n - The interval or sequence of intervals in seconds.
 *   If `n` is a number, the callback will be invoked repeatedly with a fixed interval.
 *   If `n` is an array of numbers, the callback will be invoked with the given (absolute) intervals in sequence.
 * @param cb - The callback function to be invoked.
 * @returns An object with a `stop` method to stop the polling.
 * @example
 * // Invoke a callback function every 2 seconds
 * const poller = poll(2, () => console.log('Hello'))
 *
 * // Invoke a callback function after 1s, 2s, 5s, then stop
 * const poller = poll([1, 2, 5], () => console.log('World'))
 *
 * // (manually) Stop the polling
 * poller.stop()
 */
export function poll(n: number | number[], cb: () => void) {
  const dummy = { stop: () => {} }
  if (!Number.isFinite(n) && !Array.isArray(n)) return dummy

  if (!Array.isArray(n) && Number.isFinite(n) && n > 0) {
    const interval = setInterval(cb, n * 1000)
    return { stop: () => clearInterval(interval) }
  }

  if (!Array.isArray(n)) return dummy

  let index = 0
  let total = n[index] * 1000
  let timeout: NodeJS.Timeout

  const callback = () => {
    cb()
    index++
    if (n.length > index) {
      const t = n[index] * 1000 - total
      total = n[index] * 1000
      timeout = setTimeout(callback, t)
    }
  }
  timeout = setTimeout(callback, total)

  return { stop: () => clearTimeout(timeout) }
}

export function shortenString(str: string, len: number): string {
  return str.length > len ? str.slice(0, len / 2) + '...' + str.slice(-len / 2) : str
}

export function isSameArray(list1: string[], list2: string[]): boolean {
  if (list1.length !== list2.length) return false

  const sorted1 = list1.toSorted()
  const sorted2 = list2.toSorted()

  return sorted1.every((value: string, index: number) => value === sorted2[index])
}
