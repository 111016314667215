import { defineStore } from 'pinia'
import { fetchReportingEntity, getEntityRoles, createReportingEntity } from '@composable/useApi'
import { updateUser } from '@api/user'
import { useDatasetStore } from './dataset'
import { useSnackStore } from '@store/snackStore'
import { useUserStore } from '@store/user'
import type { AxiosError } from 'axios'

export interface ReportingEntityOptionsChoices {
  display_name: string
  value: number | string
}

export interface ReportingEntityOptions {
  actions: {
    POST: {
      role: {
        choices: ReportingEntityOptionsChoices[]
      }
      ghgp_inventorization_role: {
        choices: ReportingEntityOptionsChoices[]
      }
    }
  }
  description: string
  name: string
  parses: string[]
  renders: []
}

export type EntityRole = 'Tenant' | 'Organisation' | 'Operation' | 'Project' | 'Abstract'

export interface ReportingEntity {
  url: string
  id: string
  hierarchy_id: string
  name: string
  role: number
  superior_reporting_entity: string | null
  datasets: string[]
  subordinate_reporting_entities: string[]
  get_unlocked_reporting_scopes: string[]
  meta_data: MetaData | null
  get_role: EntityRole
  get_meta_data: null
  settings: UserSettings | null
  ghgp_inventorization_role: string
  ghgp_inventorization_scalar: number | null
}

type StoredReportingEntity = StoredData<ReportingEntity> & {
  allReportingEntitiesStaff: ReportingEntity[]
  entityRoles: ReportingEntityOptionsChoices[]
  ghgpInventorizationRoles: ReportingEntityOptionsChoices[]
}

export type ReportingEntityPostObj = Pick<
  ReportingEntity,
  'name' | 'role' | 'superior_reporting_entity' | 'ghgp_inventorization_role' | 'ghgp_inventorization_scalar'
> & {
  Role: string
  settings: Partial<UserSettings>
}

export const useReportingEntitiesStore = defineStore('reportingEntities', {
  state: (): StoredReportingEntity => ({
    data: [],
    allReportingEntitiesStaff: [],
    current: null,
    loading: true,
    ready: false,
    statusCode: 0,
    statusMessage: '',
    entityRoles: [],
    ghgpInventorizationRoles: [],
  }),

  getters: {
    entityAsDropDownElements(state): LabeledValue[] {
      if (!state.data.length) return []
      return state.data.map((entity) => ({
        label: entity.name,
        value: entity.id,
      }))
    },
    entityRoleOptions(state): LabeledValue<number>[] {
      if (!state.entityRoles.length) return []
      const roles: LabeledValue<number>[] = []
      state.entityRoles.forEach((role: ReportingEntityOptionsChoices) => {
        if (role.display_name !== 'Tenant') {
          roles.push({
            value: role.value as number,
            label: role.display_name,
          })
        }
      })
      return roles
    },
    ghgpInventorizationRoleOptions(state): LabeledValue[] {
      return state.ghgpInventorizationRoles.map((role) => ({
        value: role.value as string,
        label: role.display_name,
      }))
    },
  },

  actions: {
    resetState() {
      this.data = []
      this.allReportingEntitiesStaff = []
      this.current = null
      this.loading = true
      this.ready = false
      this.statusCode = 0
      this.statusMessage = ''

      // resetting dataset store as well, to ensure a clean app state
      useDatasetStore().resetState()
    },
    async fetchData(id: string) {
      const snackBar = useSnackStore()
      this.resetState()
      try {
        // importing required functions from dataset store
        const { fetchData: fetchDatasets } = useDatasetStore()
        this.data = await fetchReportingEntity()
        this.current = this.data.filter((entity) => {
          return entity.id === id
        })[0]
        await fetchDatasets(this.current.datasets)

        localStorage.setItem('reporting_entity', this.current.id)

        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('errorFetchingEntities')
      } finally {
        this.loading = false
      }
    },

    async fetchAllReportingEntitiesForStaff() {
      const snackBar = useSnackStore()
      try {
        // importing required functions from dataset store
        this.allReportingEntitiesStaff = await fetchReportingEntity(1000, true)

        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('errorFetchingEntities')
      }
    },

    async fetchEntityRoles() {
      try {
        const roles = await getEntityRoles()
        this.entityRoles = roles.actions.POST.role.choices
        this.ghgpInventorizationRoles = roles.actions.POST.ghgp_inventorization_role.choices
      } catch (error) {
        this.entityRoles = []
        this.ghgpInventorizationRoles = []
      }
    },

    getParentEntity(id: string) {
      if (!this.data.length) return ''
      const parent =
        this.data.find((entity) => entity.id === id && entity.superior_reporting_entity)?.superior_reporting_entity ||
        ''
      return this.getEntityName(parent)
    },

    async updateCurrentEntity(id: string) {
      this.loading = true

      const { fetchData: fetchDatasets, data: datasets } = useDatasetStore()

      this.current = this.data.find((entity) => entity.id === id) || null
      if (!this.current) {
        console.error('ERROR: tried to switch to non-existing entity', id)
        window.location.reload() // otherwise we end up in a bad place
        return // typescript doesn't know about page reload
      }
      localStorage.setItem('reporting_entity', id)
      await fetchDatasets(this.current.datasets)

      localStorage.setItem('reporting_entity', this.current.id) // used in user store
      const userStore = useUserStore()
      const privateSettings = userStore.user.private_settings
      if (!privateSettings) {
        userStore.user.private_settings = {
          dataset: datasets[0]?.id || 'no-dataset',
        }
      } else {
        privateSettings.dataset = datasets[0]?.id || 'no-dataset'
      }

      const updatedUser = await updateUser(userStore.user.id, { private_settings: privateSettings })
      await userStore.setUser(updatedUser)
      this.loading = false
      return
    },

    getEntityName(id: string) {
      if (!this.data.length) return ''
      const name = this.data.find((entity) => entity.id === id)?.name || '-'
      return name
    },
    async createEntity(data: ReportingEntityPostObj) {
      const snackBar = useSnackStore()
      try {
        this.loading = true
        const reportingEntity = await createReportingEntity(data)
        this.data = [reportingEntity, ...this.data]
        snackBar.success('reportingEntityCreated')
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('reportingEntityNotCreated')
      } finally {
        this.loading = false
      }
    },
    /**
     * Returns a set of all the IDs of the sub-entities of the given entity,
     * including their own sub-entities.
     *
     * @param entity - root entity
     * @returns A set of all found IDs of the sub-entities and the root.
     */
    getSubEntityIds(rootEntity?: ReportingEntity): Set<string> {
      rootEntity = rootEntity ?? this.current ?? undefined
      if (rootEntity === undefined) return new Set<string>()
      const rootId = rootEntity.id // the f*ck typescript?!

      const entityIds = this.data.filter((e) => e.hierarchy_id.includes(rootId)).map((e) => e.id)
      return new Set<string>(entityIds)
    },
  },
})
