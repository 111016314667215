<script setup lang="ts">
import { ref } from 'vue'
import { FormKitIcon } from '@formkit/vue'
import useLocales from '@composable/useLocales'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import TextField from '@component/forms/TextField.vue'
import CGButton from '@component/CGButton.vue'
import CGLabel from '@component/CGLabel.vue'
import CGInfoBox from '@component/CGInfoBox.vue'
import { type Checklist } from '@store/tasks'

export interface Props {
  list: Checklist[]
  isAllowedToEdit?: boolean
  onUpdate: (list: Checklist[]) => void
}

const { LL } = useLocales()
withDefaults(defineProps<Props>(), { isAllowedToEdit: true, })

const newItem = ref('')
const isItemAdding = ref(false)
const checklist = ref<Checklist[]>(
  __props.list.map((item) => {
    const state = item.status === 'to-do' ? false : item.status === 'done' ? true : item.status
    return { text: item.text, status: state }
  }),
)

const pt = {
  root: {
    class: 'w-full text-left text-wrap max-h-80 overflow-y-auto overflow-x-hidden',
  },
  bodyRow: {
    class: 'hover:bg-gray/5',
  },
  column: {
    bodycell: 'px-1 text-left',
    rowreordericon: {
      class: 'cursor-move',
    },
  },
}

function toggleInputField() {
  isItemAdding.value = !isItemAdding.value
}

function handleUpdate() {
  __props.onUpdate(checklist.value.map((item) => ({ text: item.text, status: item.status ? 'done' : 'to-do' })))
}

function handleRemove(index: number) {
  checklist.value.splice(index, 1)
  isItemAdding.value = false
  handleUpdate()
}

function onRowReorder(event: any) {
  checklist.value = event.value
  isItemAdding.value = false
  handleUpdate()
}

function onEditItem(event: any) {
  const { data, newValue, field } = event
  if (newValue.trim().length > 0) data[field] = newValue
  isItemAdding.value = false
  handleUpdate()
}

function onCheckItem(event: any) {
  checklist.value[event].status = !checklist.value[event].status
  handleUpdate()
}

function onAddItem() {
  if (newItem.value && newItem.value.replace(/ /g, '').length)
    checklist.value.push({ text: newItem.value.trimStart().trimEnd(), status: false })
  newItem.value = ''
  handleUpdate()
}
</script>
<template>
  <DataTable
    :value="checklist"
    :editMode="isAllowedToEdit ? 'cell' : undefined"
    @cell-edit-complete="onEditItem"
    @rowReorder="onRowReorder"
    :reorderableColumns="true"
    :pt
  >
    <Column rowReorder v-if="isAllowedToEdit" />
    <Column field="status">
      <template #body="{ data, field, index }">
        <FormKit type="checkbox" v-model="data[field]" @click="onCheckItem(index)" :disabled="!isAllowedToEdit" />
      </template>
    </Column>

    <Column field="text" class="w-3/4">
      <template #body="{ data, field }">
        <CGLabel :class="data.status ? 'line-through text-gray-light' : ''"> {{ data[field] }}</CGLabel>
      </template>
      <template #editor="{ data, field }">
        <template v-if="field === 'text'">
          <TextField v-model="data[field]" maxLength="100" autofocus />
        </template>
      </template>
    </Column>

    <Column>
      <template #body="{ index }">
        <CGButton
          tertiary
          icon="deleteIcon"
          iconClass="text-gray-light !-mr-2"
          :disabled="!isAllowedToEdit"
          @click="handleRemove(index)"
          data-cy="task__newtask-checklist-deleteItem-button"
        />
      </template>
    </Column>
  </DataTable>

  <div v-if="checklist.length < 99">
    <div v-if="isItemAdding && isAllowedToEdit">
      <div class="flex gap-2 pt-2">
        <TextField
          class="w-full"
          v-model="newItem"
          maxLength="100"
          :placeholder="LL.taskModal.checklist.addItem()"
          @keyup.enter="onAddItem"
          @keyup.esc="toggleInputField"
          autofocus
          data-cy="task__newtask-checklist-additem-text-field"
        />

        <FormKitIcon
          class="w-10 p-2 rounded-md bg-gray-btn dark:bg-gray-dark text-gray cursor-pointer"
          icon="check"
          @click="onAddItem"
          data-cy="task__newtask-checklist-additem-send-button"
        />
      </div>

      <CGLabel class="text-xs text-gray-light"> ({{ newItem.length }}/100) </CGLabel>
    </div>

    <CGButton
      tertiary
      icon="addCircle"
      :label="LL.taskModal.checklist.addItem()"
      :disabled="!isAllowedToEdit"
      class="no-underline !px-0 text-sm"
      :class="isAllowedToEdit ? 'cursor-pointer' : 'cursor-block'"
      @click="toggleInputField"
      data-cy="task__newtask-checklist-additem-button"
    />
  </div>

  <CGInfoBox v-else class="my-2 py-2" :text="LL.taskModal.checklist.maxInfo()" type="info" big />
</template>
