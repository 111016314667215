<script setup lang="ts">
import { computed, watch } from 'vue'
import Select, { type SelectPassThroughOptions } from 'primevue/select'
import type { FormKitNode } from '@formkit/core'

export interface Props {
  options?: LabeledValue<string | boolean | null>[] | GroupedLabeledValue<string | null>[]
  placeholder?: string
  disabled?: boolean
  filter?: boolean
  grouped?: boolean
  filled?: boolean
  hideKey?: boolean // used to not reactively close the parent component when the value changes
  context?: {
    // for use as formkit element
    options: LabeledValue[] | RecursiveLabeledValue[]
    placeholder?: string
    disabled?: boolean
    filter?: boolean
    grouped?: boolean
    filled?: boolean
    node: FormKitNode
  }
}

export interface Events {
  (e: 'update', value: any): void
}

const props = defineProps<Props>()
const emit = defineEmits<Events>()
const selection = defineModel<any>()

const filled = computed(() => props.filled || props.context?.filled)

// this maps selection to formkit input value
if (props.context?.node.value) selection.value = props.context.node.value
watch(selection, async () => {
  if (!props.context?.node) return
  await props.context.node.input(selection.value)
})

const pt = computed<SelectPassThroughOptions>(() => ({
  root: [
    'h-10 mt-1 flex items-center relative p-2 transition duration-200 justify-between',
    'border rounded-lg',
    'text-black dark:text-white',
    'cursor-pointer select-none',
    {
      'opacity-60 pointer-events-none cursor-default': props.disabled,
      'border-gray-border dark:border-gray-dark bg-white dark:bg-black': !filled.value,
      'border-gray-btn dark:border-gray-dark bg-gray-btn dark:bg-gray-dark/50': filled.value,
    },
  ],
  label: [
    'block flex-auto bg-transparent border-0 pr-2',
    {
      'text-black dark:text-white': ['string', 'boolean'].includes(typeof selection.value),
      'text-gray-light': !['string', 'boolean'].includes(typeof selection.value) && !filled.value,
      'font-bold': filled.value,
    },
    'cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap appearance-none',
  ],
  overlay:
    'max-w-[500px] absolute top-0 left-0 border-0 dark:border rounded-lg shadow-md bg-white dark:bg-black dark:text-white dark:border-white',
  header:
    'flex items-center justify-between py-3 px-5 m-0 border-b rounded-tl-md rounded-tr-md text-black dark:text-white bg-white-soft dark:bg-black-soft border-gray-border',
  listContainer: 'overflow-auto',
  list: 'py-3 list-none m-0',
  option: ({ context }) => [
    'flex gap-2 items-center relative',
    'm-0 py-3 px-5',
    'border-0 transition-shadow duration-200 cursor-pointer hover:bg-gray/20',
    context.disabled ? 'cursor-default pointer-events-none text-gray' : 'cursor-pointer',
  ],
  optionGroup: 'text-lg font-bold m-0 py-3 px-5 text-black dark:text-white cursor-auto',
  pcFilterContainer: {
    root: 'relative w-full mx-2',
  },
  pcFilter: {
    root: 'font-sans leading-none pr-7 py-3 px-3 -mr-7 w-full text-black dark:text-white bg-white dark:bg-black-soft border-gray-border placeholder:text-gray dark:placeholder:text-gray-btn border rounded-lg appearance-none transition duration-200 focus:ring focus:outline-none focus:outline-offset-0 focus:ring-blue-400 dark:focus:ring-white-soft appearance-none',
  },
  filterIcon: 'absolute top-1/2 right-8 -mt-2',
  loadingIcon: 'animate-spin',
  clearIcon: 'text-black absolute top-1/2 right-12 -mt-2',
  emptyMessage: 'leading-none py-3 px-5 text-error bg-transparent',
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
}))
</script>

<template>
  <Select
    :pt
    v-model="selection"
    optionLabel="label"
    optionValue="value"
    optionDisabled="disabled"
    :optionGroupLabel="grouped || context?.grouped ? 'label' : undefined"
    :optionGroupChildren="grouped || context?.grouped ? 'items' : undefined"
    :filter="filter || context?.filter"
    :options="options ?? context?.options ?? []"
    :placeholder="placeholder ?? context?.placeholder ?? ''"
    :disabled="disabled || context?.disabled"
    :filled
    @update:modelValue="emit('update', selection)"
    :key="!hideKey && selection"
  />
</template>
