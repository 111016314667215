import { defaultConfig } from '@formkit/vue'
import { de } from '@formkit/i18n'
import {
  search,
  reorder,
  settings,
  share,
  tools,
  list,
  caretDown,
  spinner,
  eye,
  fileDoc,
  group as stack,
} from '@formkit/icons'
import arrowUp from '@/assets/icons/arrowUp.svg?raw'
import arrowDown from '@/assets/icons/arrowDown.svg?raw'
import email from '@/assets/icons/mail.svg?raw'
import password from '@/assets/icons/lock.svg?raw'
import dashboard from '@/assets/icons/speedometer.svg?raw'
import leaf from '@/assets/icons/leaf.svg?raw'
import clipboard from '@/assets/icons/clipboard.svg?raw'
import fileSearch from '@/assets/icons/fileSearch.svg?raw'
import fileEdit from '@/assets/icons/fileEdit.svg?raw'
import configuration from '@/assets/icons/Configuration.svg?raw'
import edit from '@/assets/icons/Edit.svg?raw'
import askQuestion from '@/assets/icons/messageQuestion.svg?raw'
import reactivate from 'remixicon/icons/User/user-add-line.svg?raw'
import sun from 'remixicon/icons/Weather/sun-line.svg?raw'
import moon from 'remixicon/icons/Weather/moon-line.svg?raw'
import alert from 'remixicon/icons/System/error-warning-line.svg?raw'
import block from 'remixicon/icons/System/forbid-2-line.svg?raw'
import check from '@/assets/icons/check.svg?raw'
import circleArrowRight from '@/assets/icons/arrowCircleRight.svg?raw'
import circleArrowRightInverted from '@/assets/icons/arrowCircleRightInvert.svg?raw'
import circleCaretRightOutline from '@/assets/icons/caretCircleRightOutline.svg?raw'
import circleArrowRightOutline from '@/assets/icons/arrowCircleRightOutline.svg?raw'
import calendar from '@/assets/icons/calendar.svg?raw'
import add from '@/assets/icons/add.svg?raw'
import deleteIcon from '@/assets/icons/delete.svg?raw'
import notification from '@/assets/icons/notification.svg?raw'
import maximize from '@/assets/icons/maximize.svg?raw'
import caretLeft from '@/assets/icons/caretLeft.svg?raw'
import info from '@/assets/icons/info.svg?raw'
import file from '@/assets/icons/file.svg?raw'
import document from '@/assets/icons/document.svg?raw'
import documentUpload from '@/assets/icons/documentUpload.svg?raw'
import documentDownload from '@/assets/icons/documentDownload.svg?raw'
import tickCircle from '@/assets/icons/tickCircle.svg?raw'
import error from '@/assets/icons/error.svg?raw'
import close from '@/assets/icons/error.svg?raw'
import cross from '@/assets/icons/close.svg?raw'
import linkExternal from '@/assets/icons/externalLink.svg?raw'
import link from '@/assets/icons/link.svg?raw'
import filter from '@/assets/icons/filter.svg?raw'
import minusSquare from '@/assets/icons/minusSquare.svg?raw'
import accounting from '@/assets/icons/accounting.svg?raw'
import download from '@/assets/icons/Download.svg?raw'
import energy from '@/assets/icons/energy.svg?raw'
import water from '@/assets/icons/water.svg?raw'
import gasStation from '@/assets/icons/gasStation.svg?raw'
import refrigerant from '@/assets/icons/refrigerant.svg?raw'
import airplane from '@/assets/icons/airplane.svg?raw'
import bus from '@/assets/icons/bus.svg?raw'
import car from '@/assets/icons/car.svg?raw'
import home from '@/assets/icons/house.svg?raw'
import shop from '@/assets/icons/shop.svg?raw'
import creditCard from '@/assets/icons/creditCard.svg?raw'
import tree from '@/assets/icons/tree.svg?raw'
import globe from '@/assets/icons/globe.svg?raw'
import person from '@/assets/icons/person.svg?raw'
import help from '@/assets/icons/helpCircle.svg?raw'
import more from '@/assets/icons/more.svg?raw'
import building from '@/assets/icons/building.svg?raw'
import folder from '@/assets/icons/folder.svg?raw'
import smsTracking from '@/assets/icons/smsTracking.svg?raw'
import logo from '@/assets/logo_full_new.svg?raw'
import tickSquare from '@/assets/icons/tickSquare.svg?raw'
import entityTenant from '@/assets/icons/entityTenant.svg?raw'
import entityOrg from '@/assets/icons/entityOrg.svg?raw'
import entityProject from '@/assets/icons/entityProject.svg?raw'
import entityAbstract from '@/assets/icons/entityAbstract.svg?raw'
import dataTree from '@/assets/icons/dataTree.svg?raw'
import addSquare from '@/assets/icons/addSquare.svg?raw'
import unlock from '@/assets/icons/unlock.svg?raw'
import locked from '@/assets/icons/locked.svg?raw'
import ellipse from '@/assets/icons/ellipse.svg?raw'
import copy from '@/assets/icons/copy.svg?raw'
import pie from '@/assets/icons/pie.svg?raw'
import timer from '@/assets/icons/timer.svg?raw'
import play from '@/assets/icons/play.svg?raw'
import pause from '@/assets/icons/pause.svg?raw'
import send from '@/assets/icons/send.svg?raw'
import merge from '@/assets/icons/merge.svg?raw'
import telescope from '@/assets/icons/telescope.svg?raw'
import box from '@/assets/icons/box.svg?raw'
import menu from '@/assets/icons/menu.svg?raw'
import message from '@/assets/icons/message.svg?raw'
import metrics from '@/assets/icons/metrics.svg?raw'
import doubleArrowUp from '@/assets/icons/doubleArrowUp.svg?raw'
import checkCircle from '@/assets/icons/checkCircle.svg?raw'
import minusCircle from '@/assets/icons/minusCircle.svg?raw'
import academicCap from '@/assets/icons/academicCap.svg?raw'
import truck from '@/assets/icons/truck.svg?raw'
import shieldSearch from '@/assets/icons/shieldSearch.svg?raw'
import clock from '@/assets/icons/clock.svg?raw'
import comment from '@/assets/icons/comment.svg?raw'
import searchStatus from '@/assets/icons/searchStatus.svg?raw'
import grid from '@/assets/icons/grid.svg?raw'
import danger from '@/assets/icons/danger.svg?raw'
import zoomIn from '@/assets/icons/zoomIn.svg?raw'
import zoomOut from '@/assets/icons/zoomOut.svg?raw'
import colResizer from '@/assets/icons/colResizer.svg?raw'
import dot from '@/assets/icons/dot.svg?raw'
import template from '@/assets/icons/flagAdd.svg?raw'
import tag from '@/assets/icons/tag.svg?raw'
import percentageSquare from '@/assets/icons/percentageSquare.svg?raw'
import messageText from '@/assets/icons/message-text.svg?raw'
import messageAdd from '@/assets/icons/message-add.svg?raw'
import addCircle from '@/assets/icons/add-circle.svg?raw'
import eyeClosed from '@/assets/icons/eyeClosed.svg?raw'
import people from '@/assets/icons/people.svg?raw'

export { plugin as formKitPlugin } from '@formkit/vue'
import { createAutoHeightTextareaPlugin } from '@formkit/addons'

export const formKitConfig = defaultConfig({
  locales: { de }, // en locale is included by default
  locale: 'de',
  plugins: [createAutoHeightTextareaPlugin()],
  icons: {
    stack,
    close,
    download,
    email,
    password,
    search,
    reorder,
    settings,
    people,
    share,
    linkExternal,
    link,
    add,
    dashboard,
    leaf,
    clipboard,
    fileSearch,
    fileEdit,
    configuration,
    tools,
    list,
    edit,
    file,
    caretDown,
    deleteIcon,
    askQuestion,
    reactivate,
    sun,
    moon,
    alert,
    block,
    check,
    circleArrowRight,
    circleArrowRightInverted,
    calendar,
    notification,
    maximize,
    caretLeft,
    info,
    document,
    documentUpload,
    documentDownload,
    tickCircle,
    error,
    cross,
    filter,
    arrowUp,
    arrowDown,
    minusSquare,
    accounting,
    energy,
    water,
    gasStation,
    refrigerant,
    airplane,
    bus,
    car,
    home,
    shop,
    creditCard,
    tree,
    spinner,
    eye,
    fileDoc,
    zoomIn,
    zoomOut,
    globe,
    person,
    help,
    more,
    building,
    folder,
    smsTracking,
    logo,
    circleArrowRightOutline,
    tickSquare,
    entityAbstract,
    entityProject,
    entityOrg,
    entityTenant,
    dataTree,
    addSquare,
    unlock,
    locked,
    ellipse,
    copy,
    pie,
    timer,
    play,
    pause,
    send,
    merge,
    telescope,
    box,
    menu,
    message,
    metrics,
    circleCaretRightOutline,
    doubleArrowUp,
    checkCircle,
    minusCircle,
    academicCap,
    truck,
    shieldSearch,
    clock,
    comment,
    searchStatus,
    grid,
    danger,
    colResizer,
    dot,
    template,
    tag,
    percentageSquare,
    messageText,
    messageAdd,
    addCircle,
    eyeClosed,
  },
})
