<script setup lang="ts">
import Toast from 'primevue/toast'

const pt = {
  root: {
    class: 'fixed top-8 right-8 rounded-lg w-96 text-white',
  },
  message: ({ props }: any) => ({
    class: [
      'w-full my-4 rounded-lg shadow-lg border-solid border-l-4 backdrop-blur-md',
      {
        'bg-primary/70 border-blue': props.message.severity === 'info',
        'bg-success/70 border-green': props.message.severity === 'success',
        'bg-warning/70 border-yellow': props.message.severity === 'warn',
        'bg-danger/70 border-red': props.message.severity === 'error',
      },
    ],
  }),
  messageContent: ({ props }: any) => ({
    class: [
      'flex p-4 gap-4',
      {
        'items-start': props.message.summary,
        'items-center': !props.message.summary,
      },
    ],
  }),
  messageIcon: {
    class: 'w-6 h-6 text-2xl shrink-0',
  },
  messageText: {
    class: 'text-base leading-none',
  },
  summary: {
    class: 'block font-bold',
  },
  closebutton: {
    class: [
      'flex flex-1 items-center justify-center w-8 h-8 ml-auto relative',
      'rounded-full bg-transparent overflow-hidden',
      'transition duration-200 ease-in-out',
      'hover:bg-surface-0/50 dark:hover:bg-surface-0/10',
    ],
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass: 'transition-[transform,opacity] duration-300',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
}
</script>

<template>
  <Toast :pt />
</template>
