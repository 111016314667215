<script setup lang="ts">
import { ref, computed } from 'vue'
import SelectButton, { type SelectButtonPassThroughAttributes } from 'primevue/selectbutton'

type Option = LabeledValue<Optional<Primitive>> & { disabled?: boolean }

export interface Props {
  disabled?: boolean
  invalid?: boolean
  options: Option[]
  value: Primitive | null
}
const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update', value: unknown): void
}>()

const selectedLabel = computed(() => {
  const selectedOption = props.options.find((opt) => opt.value === props.value)
  return selectedOption?.label ?? ''
})

const selection = ref({ label: selectedLabel.value, value: props.value })

const pt = {
  root: () => ({
    class: [
      'inline-flex rounded-lg border border-gray-border p-1',
      { 'opacity-50 select-none pointer-events-none cursor-default': props.disabled },
    ],
  }),
  pcToggleButton: () => ({
    root: ({ context, props: btnProps }: SelectButtonPassThroughAttributes) => ({
      class: [
        `relative leading-none inline-flex items-center align-bottom text-center px-4 py-3  rounded-md `,
        {
          'bg-white dark:bg-black': !context.active,
          'text-black dark:text-white': !context.active,
          'border-0': !context.active && !props.invalid,
          'bg-blue-100 dark:bg-blue text-blue dark:text-white':
            context.active || btnProps.onLabel === selection.value.label,
        },
        { 'border-red dark:border-red-light': props.invalid },
        {
          'hover:bg-blue-100 hover:text-blue dark:hover:bg-blue dark:hover:text-white':
            !context.active && !props.invalid,
          'hover:bg-blue-100 hover:text-blue': context.active || btnProps.onLabel === selection.value.label,
        },
        { 'opacity-50 select-none pointer-events-none cursor-default': context.disabled },
        'transition duration-200 cursor-pointer select-none overflow-hidden',
      ],
    }),
  }),
}

function handleUpdate(value: unknown) {
  selection.value.label = props.options.find((opt) => opt.value === value)?.label ?? selection.value.label
  emit('update', selection.value)
}
</script>

<template>
  <SelectButton
    :pt
    v-bind="{ options, disabled, invalid }"
    v-model="selection.value"
    optionLabel="label"
    optionValue="value"
    optionDisabled="disabled"
    @update:modelValue="handleUpdate"
  />
</template>
