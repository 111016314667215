<script setup lang="ts">
import { ref, computed, reactive } from 'vue'
import { min } from 'date-fns'
import { useRouter } from 'vue-router/auto'

import { useTasksStore, type TaskItem, type TaskCreateObj, type Checklist } from '@store/tasks'
import useLocales from '@composable/useLocales'
import { linkDataForTasks, getDueDate, getTaskIcon } from '@util/tasks'
import { fetchUsers, fetchUser } from '@util/reporting'
import type { TreeNode } from 'primevue/treenode'
import { FormKitIcon } from '@formkit/vue'

import Dialog from '@component/NewDialog.vue'
import TextField from '@component/forms/TextField.vue'
import InputField from '@component/QuestionnaireSection/InputField.vue'
import SelectBox, { type ComplexOption } from '@component/forms/SelectBox.vue'
import UserOption from '@component/QuestionnaireSection/UserOption.vue'
import TaskStatus from '@component/Tasks/TaskStatus.vue'
import NestedSelect from '@component/NestedSelect.vue'
import Link from '@component/Tasks/Link.vue'
import CGLabel from '@component/CGLabel.vue'
import CGSelectBox from '@component/CGSelectBox.vue'
import CGPill from '@component/CGPill.vue'
import CGChecklists from '@component/CGChecklists.vue'
import CGButton from '@component/CGButton.vue'

import projectsAdapter, { type Projects } from '@adapter/projects'
import { useReportingEntitiesStore } from '@store/reportingEntities'

export interface Props {
  task: TaskItem
  showTaskViewDialog: boolean
  displayName: string
  initials: string
  isAllowedToEdit: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update', value: boolean): void
  (e: 'delete'): void
  (e: 'cancel'): void
}>()

const openDialog = ref(props.showTaskViewDialog)
const router = useRouter()

const { LL } = useLocales()
const taskStore = useTasksStore()

const taskFormData: TaskCreateObj = reactive({
  title: props.task.title,
  description: props.task.description,
  assignee_id: props.task.assignee_id ?? null,
  status: props.task.status,
  due_at: props.task.due_at,
  link_data: {
    name: props.task.link_data.name || props.task.link_data.page,
    kwargs: { ...(props.task.link_data.kwargs ?? props.task.link_data.kwargs) },
  },
  project_id: props.task.project?.id ?? null,
  checklist: props.task.checklist,
})

const { fetch: fetchProjectsByEntity, getProjects } = projectsAdapter()
const projects = ref<Projects[]>()
const entityStore = useReportingEntitiesStore()

async function fetchProjects() {
  // found out that, some old task do not have entity. So will use current entity instead
  const entityId = props.task.reporting_entity_id.length ? props.task.reporting_entity_id : entityStore.current?.id
  if (entityId) {
    await fetchProjectsByEntity(props.task.reporting_entity_id).then(() => {
      projects.value = getProjects(entityId)
    })
  }
}
void fetchProjects()

const projectsOptions = computed(() => {
  const options: { label: string; value: string | null }[] = []
  projects.value?.forEach((project) => {
    options.unshift({ label: project.title, value: project.id })
  })
  options.unshift({ label: LL.value.none(), value: null })
  return options
})

const assignedUser = ref<Optional<ComplexOption>>(null)
const showInlineTitleEdit = ref<Optional<'title' | 'description' | 'user'>>(null)
const assigneeChanged = ref(false)

const linkData: TreeNode[] = linkDataForTasks(LL)

const dueDate = computed(() => getDueDate(taskFormData.due_at))

function resetFormData() {
  taskFormData.due_at = props.task.due_at
  taskFormData.link_data = props.task.link_data
  taskFormData.status = props.task.status
  taskFormData.title = props.task.title
  taskFormData.description = props.task.description
  showInlineTitleEdit.value = null
  emit('cancel')
}

const saveEditedValues = () => {
  if (taskFormData.title && taskFormData.due_at) {
    void taskStore.editSelectedTask(taskFormData, props.task.id)
    emit('cancel')
  }
}

async function setAssignedUser(userId: string | null = null) {
  if (userId) {
    assignedUser.value = await fetchUser(userId)
    taskFormData.assignee_id = userId
    assigneeChanged.value = true
    showInlineTitleEdit.value = null
    return
  }
  if (taskFormData.assignee_id) assigneeChanged.value = true
  assignedUser.value = taskFormData.assignee_id ? await fetchUser(taskFormData.assignee_id) : null
}

function resetAssignee() {
  taskFormData.assignee_id = null
  assignedUser.value = null
}

function updateLinkedName(name: string) {
  taskFormData.link_data.name = name
}
function clearLinkData() {
  taskFormData.link_data.name = ''
}

void setAssignedUser()

function handleRoute(route: string) {
  resetFormData()
  openDialog.value = false
  void router.push(route)
}

async function updateChecklist(list: Checklist[]) {
  taskFormData.checklist = list
  await taskStore.updateChecklistTask(taskFormData.checklist, props.task.id)
}
</script>
<template>
  <Dialog
    v-if="openDialog"
    type="confirm"
    static
    :header="''"
    width="550px"
    textColor="dark:text-white"
    no-footer
    zIndex="100"
    @cancel="resetFormData"
  >
    <template #header>
      <div class="flex w-full justify-between items-start">
        <div class="flex items-center gap-2 font-medium text-lg">
          <FormKitIcon
            class="flex-none inline-block mr-2 rounded-md cursor-pointer text-gray-mid dark:text-white w-4"
            :icon="getTaskIcon(props.task.link_data?.name)"
            :title="props.task.link_data?.name || 'dnd'"
            @click="showInlineTitleEdit = 'title'"
          />
          <div v-if="showInlineTitleEdit !== 'title'" @click="showInlineTitleEdit = 'title'">
            {{ taskFormData.title }}
          </div>
          <div>
            <TextField
              v-if="showInlineTitleEdit === 'title'"
              :validation-label="LL.taskModal.title()"
              required
              :disabled="!isAllowedToEdit"
              v-model="taskFormData.title"
              @keyEnter="!taskFormData.title ? (showInlineTitleEdit = 'title') : (showInlineTitleEdit = null)"
              @blur="!taskFormData.title ? (showInlineTitleEdit = 'title') : (showInlineTitleEdit = null)"
              data-cy="task__board-task-item-title-field"
            />
          </div>
        </div>
        <div class="flex p-2 bg-gray-btn dark:bg-gray-dark rounded-md cursor-pointer" @click="resetFormData">
          <FormKitIcon icon="cross" class="dark:text-white w-4" />
        </div>
      </div>
    </template>
    <div class="w-100 flex flex-col gap-4 items-start my-6">
      <div class="w-full flex gap-2">
        <CGLabel class="w-1/4" @click="showInlineTitleEdit = 'description'">{{ LL.taskModal.details() }}</CGLabel>
        <CGLabel v-if="showInlineTitleEdit !== 'description'" @click="showInlineTitleEdit = 'description'">
          <span v-if="!taskFormData.description.length">({{ LL.taskModal.noDetails() }})</span>
          {{ taskFormData.description }}
        </CGLabel>
        <InputField
          v-if="showInlineTitleEdit === 'description'"
          data-cy="task__board-task-item-details-field"
          :classes="'2xl:min-w-[450px] min-h-20 p-1 max-w-56'"
          type="textarea"
          :value="taskFormData.description as string"
          @update="taskFormData.description = $event as string"
          @blur="showInlineTitleEdit = null"
        />
      </div>
      <div class="w-full flex items-center text-sm">
        <CGLabel class="w-1/4">{{ LL.taskModal.link() }}</CGLabel>
        <Link v-if="taskFormData.link_data.name" :link="taskFormData.link_data.name" :handleRoute />
        <CGPill v-else :text="LL.pleaseSelect()" size="md" class="py-2 dark:text-gray-light" />
        <NestedSelect
          :items="linkData"
          @select="updateLinkedName"
          :disabled="!isAllowedToEdit"
          removeBG
          data-cy="task__board-task-item-link-button"
        />
        <FormKitIcon
          v-if="taskFormData.link_data.name"
          icon="deleteIcon"
          class="w-6 flex-none text-red cursor-pointer"
          @click.prevent="clearLinkData"
          :disabled="!isAllowedToEdit"
          data-cy="task__board-task-item-link-delete-button"
        />
      </div>
      <div class="w-full flex items-center gap-2">
        <CGLabel class="w-1/4" :label="LL.tasks.projects.title(1)" optional />
        <CGSelectBox
          v-model="taskFormData.project_id"
          :options="projectsOptions"
          :placeholder="LL.tasks.projects.fields.selectProject()"
          :disabled="!isAllowedToEdit"
          class="w-56"
          data-cy="task__board-task-item-project-dropdown"
        />
      </div>

      <div class="w-full flex gap-4 items-center">
        <CGLabel class="w-1/4">{{ LL.taskModal.dueDate() }}</CGLabel>
        <div class="flex flex-row items-center gap-2 justify-around">
          <TextField
            date
            required
            :disabled="!isAllowedToEdit"
            v-model="taskFormData.due_at"
            :validation-label="LL.taskModal.dueDate()"
            :min="
              min([new Date(), new Date(props.task.due_at)])
                .toISOString()
                .split('T')[0]
            "
            :placeholder="LL.taskModal.selectDate()"
            data-cy="task__board-task-item-duedate-field"
          />
          <div>
            <span v-if="dueDate > 0">in {{ dueDate }} {{ LL.taskModal.day(dueDate) }}</span>
            <CGLabel class="text-yellow" v-if="dueDate === 0"> {{ LL.taskModal.dueToday() }} </CGLabel>
            <CGLabel class="text-red" v-else-if="dueDate < 0">
              {{ dueDate * -1 }} {{ LL.taskModal.day(dueDate) }} {{ LL.taskModal.overdue() }}
            </CGLabel>
          </div>
        </div>
      </div>
      <div class="w-full flex gap-2 items-center">
        <CGLabel class="w-1/4" optional>{{ LL.taskModal.assignee() }}</CGLabel>
        <div
          v-if="showInlineTitleEdit !== 'user' && task.assignee_id"
          class="flex items-center h-10 mr-2 bg-background dark:bg-gray-mid rounded-full"
          @click="showInlineTitleEdit = 'user'"
        >
          <div class="min-w-10 p-2 text-center font-semibold bg-gray-border dark:bg-gray rounded-full">
            {{ assigneeChanged ? assignedUser?.meta?.initials : initials }}
          </div>
          <div
            class="items-center px-3 py-2 dark:bg-gray-mid overflow-ellipsis line-clamp-1 rounded-full"
            :title="displayName"
          >
            {{ assigneeChanged ? assignedUser?.label : displayName }}
          </div>
        </div>
        <SelectBox
          v-if="showInlineTitleEdit === 'user' || !task.assignee_id"
          class="max-w-56 mb-4"
          autocomplete
          :options="fetchUsers"
          prefix-icon="search"
          :placeholder="LL.taskModal.searchUsers()"
          :label="LL.taskModal.selectAssignee()"
          :model-value="assignedUser"
          @input="setAssignedUser($event as string)"
          data-cy="task__board-task-item-assignee-field"
        >
          <template #selection="{ label, meta }" v-if="taskFormData.assignee_id">
            <UserOption
              :label="label"
              :sub-label="meta.email"
              :initials="meta.initials"
              :external="meta.external"
              data-cy="task__board-task-item-assignee-listofusers"
            />
            <FormKitIcon icon="cross" class="w-4" @click="resetAssignee" />
          </template>
          <template #option="{ label, meta }">
            <UserOption
              big
              :label="label"
              :sub-label="meta.email"
              :initials="meta.initials"
              :external="meta.external"
            />
          </template>
        </SelectBox>
      </div>
      <div class="w-full flex gap-2 items-center">
        <CGLabel class="w-1/4">{{ LL.taskModal.status() }}</CGLabel>
        <TaskStatus
          :disabled="!isAllowedToEdit"
          :status="taskFormData.status"
          @update="taskFormData.status = $event"
          menu-classes="w-32"
          vAlign="top"
          data-cy="task__board-task-item-edit-status-dropdown"
        />
      </div>

      <div class="w-full flex gap-2">
        <CGLabel class="w-1/4" :label="LL.taskModal.checklist.title()" />
        <div class="w-3/4">
          <CGChecklists :isAllowedToEdit :list="taskFormData.checklist" :onUpdate="updateChecklist" />
        </div>
      </div>
    </div>

    <footer>
      <div class="flex flex-row justify-between items-start mt-4">
        <div class="flex gap-2">
          <CGButton :label="LL.cancel()" @click="emit('cancel')" />
          <FormKitIcon
            :class="`inline-block w-10 p-2 rounded-md bg-red-10 text-red-110 ${
              isAllowedToEdit ? 'cursor-pointer' : 'cursor-not-allowed'
            }`"
            icon="deleteIcon"
            @click="/* resetFormData(); */ isAllowedToEdit ? emit('delete') : null"
            data-cy="task__board-task-item-delete-button"
          />
        </div>
        <CGButton
          primary
          :disabled="!isAllowedToEdit"
          :label="LL.save()"
          @click="isAllowedToEdit ? saveEditedValues() : null"
          data-cy="task__board-task-item-save-button"
        />
      </div>
    </footer>
  </Dialog>
</template>
