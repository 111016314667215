import axios, { type AxiosError } from 'axios'
import { type Ref } from 'vue'
import { useStorage } from '@vueuse/core'

export type LoginCredentials = string | { email: string; password: string }

export type TokenStore = {
  access: string
  email: string
  exp: number | null
}

const DEFAULT_AUTH_STORE: TokenStore = {
  access: '',
  email: '',
  exp: null,
}

export const tokenStore: Ref<TokenStore> = useStorage('accessToken', DEFAULT_AUTH_STORE)
export const defaultClient = axios.create()
export const authlessClient = axios.create()
export const apiUrls = [
  null, //v0?
  import.meta.env.VITE_BASE_URL, //v1
  import.meta.env.VITE_BASE_V2_URL, //v2
  import.meta.env.VITE_BASE_V3_URL, //v3
]

defaultClient.defaults.baseURL = apiUrls[1]
authlessClient.defaults.baseURL = apiUrls[1]

defaultClient.defaults.headers.common['Content-Type'] = 'application/json'
defaultClient.defaults.headers.common['Authorization'] = `Bearer ${tokenStore.value.access}`

// stores the promise of an active token refresh
let currentRefresh: PromiseLike<void> | null = null
let isInterceptorInitialized = false

export function setToken(access: string): void {
  const tokenPayload = access.split('.')[1]
  const { email, exp } = JSON.parse(atob(tokenPayload))
  tokenStore.value = { access, email, exp }
  defaultClient.defaults.headers.common['Authorization'] = `Bearer ${access}`
}

export function removeToken(): void {
  delete defaultClient.defaults.headers.common['Authorization']
  tokenStore.value = DEFAULT_AUTH_STORE
}

export async function login(credentials: LoginCredentials): Promise<void> {
  let url, data

  if (typeof credentials === 'string') {
    url = import.meta.env.VITE_TOKEN_LOGIN_URL
    data = { token: credentials }
  } else {
    url = import.meta.env.VITE_LOGIN_URL
    data = credentials
  }

  const response = await defaultClient.post(url, data, { withCredentials: true })
  const { access } = response.data as Record<string, string>
  setToken(access)
}

async function internalRefreshToken() {
  try {
    const response = await defaultClient.post(
      import.meta.env.VITE_REFRESH_URL,
      { refresh: 'stored-in-cookie' },
      { withCredentials: true },
    )
    const { access } = response.data as Record<string, string>
    setToken(access)
    console.debug('access token refreshed')
  } catch (err) {
    console.error('Unable to refresh access token', err)
    return Promise.reject() // eslint-disable-line @typescript-eslint/prefer-promise-reject-errors
  }
}

export async function refreshAccessToken() {
  // store token refresh promise by default, to avoid duplicated refreshes
  if (currentRefresh === null) {
    currentRefresh = internalRefreshToken()
  }
  return currentRefresh
}

// called as "initAccessToken" in api/client
export default function setResponseInterceptors() {
  if (isInterceptorInitialized) return

  defaultClient.interceptors.response.use(
    // Responses with HTTP status 2XX don't need special handling (yet?)
    // TODO: might be useful for logging?!
    undefined,

    // Responses with HTTP error status (basically !2XX) will be intercepted by
    // this function. It tries to refresh the token first and logs out on failure
    async (errorResponse) => {
      if (errorResponse.response === undefined) {
        console.debug('ignoring error response, because response undefined')
        return Promise.reject(errorResponse as AxiosError)
      }

      const { status, config } = errorResponse.response
      const { url } = config as Record<string, string>

      // we only try to refresh if there is a token
      const isNotEmptyToken = !!config.headers['Authorization']
      // we explicitely do not try to refresh failed calls to the auth endpoint
      const isAuthRoute = url.includes('auth/jwt')
      const willRetry = status === 401 && isNotEmptyToken && !isAuthRoute && !config.hasBeenRetried

      console.warn(
        'error response intercepted',
        { status, url, isAuthRoute, isNotEmptyToken, willRetry },
        config.headers,
      )

      // If the auth token is invalid, try to refresh it
      // To avoid an endless loop, we check that the current request is not to the refresh URL.
      // To ensure that every request is only retried once, we set a flag on the config upon retrying.
      if (willRetry) {
        // but it might still be refreshable
        try {
          await refreshAccessToken()
        } catch {
          console.debug('logging out forcefully and reload, because refreshing access failed')
          removeToken()
          location.reload()
        } finally {
          currentRefresh = null
        }

        // refresh successful, lets retry the last request
        // but overwrite the Auth header with the new token
        config.headers['Authorization'] = defaultClient.defaults.headers.common['Authorization']
        config.hasBeenRetried = true
        if (config) return defaultClient(config)
      }

      // in case the above failed or it is not an auth issue, simply relay the error
      return Promise.reject(errorResponse as AxiosError)
    },
  )

  isInterceptorInitialized = true
}
