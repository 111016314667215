<script setup lang="ts">
import { ref, computed, reactive } from 'vue'
import { validateEmail } from '@/util/validation'
import { useRoute } from 'vue-router/auto'
import { FormKitIcon } from '@formkit/vue'
import type { TreeNode } from 'primevue/treenode'
import { useSnackStore } from '@store/snackStore'

import useLocales from '@composable/useLocales'
import Dialog from '@/components/NewDialog.vue'
import TextField from '@component/forms/TextField.vue'
import InputField from '@component/QuestionnaireSection/InputField.vue'
import NestedSelect from '@component/NestedSelect.vue'
import SelectBox, { type SelectBoxOption } from '@component/forms/SelectBox.vue'
import UserOption from '@component/QuestionnaireSection/UserOption.vue'
import CGLabel from '@component/CGLabel.vue'
import CGSelectBox from '@component/CGSelectBox.vue'
import CGPill from '@component/CGPill.vue'
import CGButton from '@component/CGButton.vue'
import CGChecklists from '@component/CGChecklists.vue'

import { fetchUsers, fetchUser } from '@util/reporting'
import { type TaskCreateObj, useTasksStore, type Checklist } from '@/stores/tasks'
import { getKwargsForTasks, linkDataForTasks } from '@util/tasks'
import { useReportingEntitiesStore } from '@store/reportingEntities'
import projectsAdapter, { type Projects } from '@adapter/projects'

export interface Props {
  showButton?: boolean
  reference?: string
  showDialogFromOutside?: boolean
  secondaryBtn?: boolean
  keyArgs: Record<string, string>
  projectId?: UUID | null
}

withDefaults(defineProps<Props>(), { showButton: true,showDialogFromOutside: false,secondaryBtn: false, })

const emit = defineEmits<{
  (e: 'cancel'): void
}>()

const { LL } = useLocales()
const snackBar = useSnackStore()
const tasksStore = useTasksStore()
const route = useRoute()

const entityStore = useReportingEntitiesStore()
const entityId = entityStore.current?.id ?? ''

const { fetch: fetchProjectsByEntity, getProjects } = projectsAdapter()
const projects = ref<Projects[]>()

async function fetchProjects() {
  await fetchProjectsByEntity(entityId).then(() => {
    projects.value = getProjects(entityId)
  })
}
fetchProjects()

const projectsOptions = computed(() => {
  const options: LabeledValue<string>[] = []
  projects.value?.forEach((project) => {
    options.unshift({ label: project.title, value: project.id })
  })
  return options
})

const linkData: TreeNode[] = linkDataForTasks(LL)

const showTaskAddDialog = ref(false)
const assignedUser = ref<SelectBoxOption | null>()

const isTaskPage = computed(() => route.name && route.name.includes('task'))

const taskFormData: TaskCreateObj = reactive({
  title: '',
  description: '',
  assignee_id: null,
  status: 'to-do',
  due_at: '',
  link_data: {
    name: '',
  },
  project_id: __props.projectId ?? null,
  checklist: [],
})
function resetAndClose() {
  taskFormData.assignee_id = null
  taskFormData.title = ''
  taskFormData.description = ''
  taskFormData.due_at = ''
  taskFormData.link_data.name = ''
  taskFormData.project_id = null
  taskFormData.checklist = []
  assignedUser.value = null
  showTaskAddDialog.value = false
  emit('cancel')
}

const assignUser = async (user: string, external = false) => {
  if (external && !validateEmail(user)) {
    snackBar.error(LL.value.taskModal.invalidEmail())
    return
  }
  taskFormData.assignee_id = user
  await setAssignedUser(taskFormData.assignee_id)
}

const createGeneralTask = async () => {
  try {
    taskFormData.link_data.kwargs = getKwargsForTasks(__props.reference as string, __props.keyArgs)
    await tasksStore.createNewTask(taskFormData)
    snackBar.success(LL.value.taskModal.taskCreated())
    showTaskAddDialog.value = false
    resetAndClose()
  } catch (e) {
    console.error('Creating a task failed:', e)
    snackBar.error(LL.value.taskModal.failedToCreateTask())
  }
}

async function setAssignedUser(userId: string | null = null) {
  if (userId) {
    assignedUser.value = await fetchUser(userId)
    return
  }
  assignedUser.value = taskFormData.assignee_id ? await fetchUser(taskFormData.assignee_id) : null
}

setAssignedUser()

function handleSelectLink(value: string) {
  taskFormData.link_data.name = value
}

function handleCtaClick() {
  showTaskAddDialog.value = true
  taskFormData.link_data.name = ''
}

function clearLinkData() {
  taskFormData.link_data.name = ''
}

function updateChecklist(list: Checklist[]) {
  taskFormData.checklist = list
}
</script>

<template>
  <CGButton
    v-if="showButton"
    :label="isTaskPage ? LL.taskModal.newTask() : LL.taskModal.addTask()"
    @click="handleCtaClick"
    :icon="isTaskPage ? 'add' : 'tickSquare'"
    :tabBtn="!isTaskPage"
    :primary="isTaskPage && !secondaryBtn"
  />
  <Dialog
    v-if="showTaskAddDialog || showDialogFromOutside"
    type="confirm"
    static
    :header="''"
    width="450px"
    textColor="dark:text-white"
    no-footer
    @cancel="resetAndClose"
  >
    <template #header>
      <div class="w-full flex justify-between items-start">
        <div class="flex flex-col gap-2">
          <div class="font-bold text-lg">{{ LL.taskModal.heading() }}</div>
          <div class="text-sm text-gray-mid dark:text-white-soft">{{ LL.taskModal.info() }}</div>
        </div>
        <FormKitIcon
          class="w-8 p-2 rounded-md bg-gray-btn cursor-pointer dark:text-white dark:bg-gray-dark z-50"
          icon="cross"
          @click="resetAndClose()"
        />
      </div>
    </template>

    <FormKit type="form" @submit="createGeneralTask" :actions="false">
      <div class="w-full flex flex-col gap-2">
        <TextField
          class="w-100 mt-2"
          required
          v-model="taskFormData.title"
          :label="LL.taskModal.title()"
          data-cy="task__newtask-title-field"
        />

        <InputField
          type="textarea"
          :classes="'p-1'"
          :value="taskFormData.description as string"
          :label="LL.taskModal.details()"
          @update="taskFormData.description = $event as string"
          data-cy="task__newtask-details-field"
        />

        <CGLabel :label="LL.tasks.projects.title(1)" optional />
        <CGSelectBox
          v-model="taskFormData.project_id"
          :options="projectsOptions"
          :placeholder="LL.tasks.projects.fields.selectProject()"
          data-cy="task__newtask-project-dropdown"
        />

        <div class="flex gap-2 mt-2 items-center text-sm">
          <CGLabel>{{ LL.taskModal.link() }}</CGLabel>
          <CGPill
            v-if="taskFormData.link_data.name?.length"
            :text="LL.taskModal.linkDataTranslations[taskFormData.link_data.name as I18NTaskLinkDataText]()"
            class="bg-primary/5 text-primary dark:text-primary dark:bg-white py-2"
            size="md"
            :onRemove="clearLinkData"
          />
          <CGPill v-else :text="LL.pleaseSelect()" size="md" class="py-2 dark:text-gray-light" />
          <NestedSelect
            :items="linkData"
            @select="handleSelectLink"
            removeBG
            class="items-end justify-end justify-items-end justify-self-end"
            data-cy="task__newtask-link-button"
          />
          <FormKitIcon
            v-if="taskFormData.link_data.name"
            icon="deleteIcon"
            class="w-6 text-red cursor-pointer"
            @click.prevent="clearLinkData"
            data-cy="task__newtask-link-delete-button"
          />
        </div>

        <SelectBox
          autocomplete
          :options="fetchUsers"
          prefix-icon="search"
          :placeholder="LL.taskModal.searchUsers()"
          :label="LL.taskModal.selectAssignee()"
          :model-value="assignedUser"
          @input="assignUser(`${$event}`)"
          optional
          data-cy="task__newtask-assignee-field"
        >
          <template #selection="{ label, meta }" v-if="taskFormData.assignee_id">
            <UserOption
              :label="label"
              :sub-label="meta.email"
              :initials="meta.initials"
              :external="meta.external"
              data-cy="task__newtask-assignee-listofusers"
            />
            <FormKitIcon
              icon="cross"
              class="w-4"
              @click="
                () => {
                  taskFormData.assignee_id = null
                  assignedUser = null
                }
              "
            />
          </template>
          <template #option="{ label, meta }">
            <UserOption
              big
              :label="label"
              :sub-label="meta.email"
              :initials="meta.initials"
              :external="meta.external"
            />
          </template>
          <!--          @TODO Uncomment when external users are enabled-->
          <!--      <template #actions="{ sublabel }">
          <UserOption big :label="LL.taskModal.assignExternal()" :sub-label="sublabel">
            <template #icon>
              <FormKitIcon icon="smsTracking" class="w-6" @click="assignUser(sublabel, true)" />
            </template>
          </UserOption>
        </template>-->
        </SelectBox>

        <TextField
          date
          class="w-[60%] dark:text-white mt-1 mb-2"
          required
          v-model="taskFormData.due_at"
          :min="new Date().toISOString().split('T')[0]"
          :label="LL.taskModal.dueDate()"
          :placeholder="LL.taskModal.selectDate()"
          data-cy="task__newtask-duedate-field"
        />

        <div>
          <CGLabel :label="LL.taskModal.checklist.title()" optional />
          <CGChecklists :list="taskFormData.checklist" :onUpdate="updateChecklist" />
        </div>

        <div class="flex flex-row pt-2 justify-between items-start">
          <CGButton
            class="cursor-pointer"
            tab-btn
            :label="LL.cancel()"
            @click="resetAndClose"
            data-cy="task__newtask-cancel-button"
          />
          <CGButton submit primary :label="LL.taskModal.createAndAssign()" data-cy="task__newtask-create-button" />
        </div>
      </div>
      <template #message></template>
    </FormKit>
  </Dialog>
</template>
