<script setup lang="ts">
import { ref } from 'vue'
import CascadeSelect from 'primevue/cascadeselect'
import { FormKitIcon } from '@formkit/vue'
import useLocales from '@composable/useLocales'
import type { TreeNode } from 'primevue/treenode'

export interface Props {
  items: TreeNode[]
  disabled?: boolean
  value?: string
  limitHeight?: boolean
  removeBG?: boolean
}

export interface Events {
  (e: 'select', value: string): void
}
const { LL } = useLocales()
withDefaults(defineProps<Props>(), {  })

const selection = ref(__props.value ?? '')
const emit = defineEmits<Events>()

function handleOptionSelect(value: string) {
  emit('select', value)
}
</script>

<template>
  <CascadeSelect
    :options="items"
    optionLabel="label"
    optionGroupLabel="label"
    optionValue="value"
    :disabled
    v-model="selection"
    :optionGroupChildren="['children']"
    :pt="{
      root: ({ props }) => ({
        class: [
          'relative flex gap-2 items-center w-fit py-1 px-2 rounded-full text-blue-800 transition-all duration-200 cursor-pointer select-none',
          {
            'opacity-60 pointer-events-none cursor-default': props.disabled,
            'bg-pages-background dark:bg-gray-btn': !removeBG,
          },
        ],
      }),
      dropdown: 'flex items-center text-black',
      overlay: {
        class: [
          'w-64 bg-white text-black dark:bg-black dark:text-white py-2 rounded-lg drop-shadow-md',
          { 'max-h-[450px] overflow-scroll': limitHeight },
        ],
      },
      option: 'relative px-2',
      optionContent:
        'relative flex items-center no-underline overflow-hidden cursor-pointer select-none hover:bg-gray-btn dark:hover:bg-gray-dark hover:rounded-lg',
      optionList: 'w-full absolute top-0 ml-64 py-2 bg-white dark:bg-black rounded-lg drop-shadow-md',
    }"
  >
    <template #option="{ option }">
      <div
        class="flex flex-row items-center py-2 px-2 w-full relative no-underline overflow-hidden cursor-pointer select-none"
        data-cy="task__newtask-link-listmodules"
        @click="handleOptionSelect(option.value as string)"
      >
        <FormKitIcon :icon="option.icon" class="w-5 h-5 mr-2 flex-none" />
        <div class="w-full overflow-ellipsis line-clamp-1" :title="option.label">{{ option.label }}</div>
      </div>
    </template>
    <template #value>
      {{ LL.taskModal.linkDataTranslations[value as I18NTaskLinkDataText]() }}
    </template>
    <template #dropdownicon>
      <slot name="dropdownicon">
        <FormKitIcon class="pl-2 cursor-pointer dark:text-white w-8" icon="fileEdit" />
      </slot>
    </template>
  </CascadeSelect>
</template>
